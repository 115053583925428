@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  background-color: #000;
  font-family: "Inter", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p{margin: 0;padding: 0;}
html,body,body > div{height: 100%;}
